body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E0F14;
  color: #EFF1F6;
}

a {
  color: #EFF1F6;
  text-decoration: none;
}

input {
  outline: none;
  border-radius: 1.02px;
  border: 0;
  padding: 1.01px 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}


/*去掉默认样式*/
.swiper-button-prev:after{
  display: none;
  }
  .swiper-button-next:after{
  display: none;
  }
  .swiper-button-next{
  width: 15px !important;
  height: 15px !important;
  background: url('./assets/next-icon.png') no-repeat center/100%;
  top: 70% !important;
}